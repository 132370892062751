import React, { useEffect, useState } from 'react';
import CustomContainer from "../../components/common/CustomContainer";
import styles from "../../assets/styles/profile.module.css";
import avtarIcon from "../../assets/images/common/avtar.png";
import InputFile from "../../components/common/InputFile";
import { FormGroup, FormLabel, MenuItem, Select, TextField } from "@mui/material";
import { handleKeyPress } from "../../utils/functions/common";
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../utils/store';
import { getProfileApi, updateProfileApi, uploadFileApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import BtnLoader from '../../components/common/BtnLoader';
import Loader from '../../components/common/Loader';

const ProfilePage: React.FC = () => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [isFileChange, setIsFileChange] = useState(false)
  const [formState, setFormState] = useState<FormState>({
    name: "",
    careId: "",
    mobile: "",
    jobRole: '',
    age: "",
    gender: "",
    profile_image: "",
  })
  const [loading, setLoading] = useState(false);

  const fetchProfileData = async () => {
    try {
      setLoading(true)
      const result = await getProfileApi();
      if (result?.success) {
        setLoading(false)
        const profileData = result?.userDetails as FormState;
        if (profileData) {
          setFormState({
            ...formState,
            name: profileData?.name,
            careId: profileData?.careId,
            mobile: profileData?.phone ? profileData?.phone : '',
            jobRole: profileData?.role ? profileData?.role : '',
            age: profileData?.age,
            gender: profileData?.gender,
            profile_image: profileData?.profilePic ? profileData?.profilePic : '',
          })
        }
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error?.data?.errors || error?.message);
    }
  }

  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleInputChange: HandleInputChangeType = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value })
  };

  const handleSelectChange: HandleSelectChangeType = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value })
  };

  const handleFileChange = (name: string, file: File | null, index?: number) => {
    if (file) {
      setIsFileChange(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result as string;
        setFormState((prev) => ({ ...prev, profile_image: base64Data }));
      };
      reader.onerror = (error) => console.error("Error reading file:", error);

    }
  };
  const RemoveProfileImg = () => {
    setFormState((prev) => ({ ...prev, profile_image: '' }))
  }

  const fetchApiUrl = async () => {
    try {
      const result = await uploadFileApi({ file: formState?.profile_image, type: 'careNavigator' })
      if (result?.success) {
        return result?.fileUrl
      }
    } catch (error: any) {
      toast.error(error?.data?.errors || error?.message);
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      const _fileData = isFileChange ? await fetchApiUrl() : '';
      const sendData = {
        name: formState?.name,
        gender: formState?.gender,
        profilePic: _fileData?.fileUrl ? _fileData?.fileUrl : formState?.profile_image,
        age: formState?.age,
        phone: formState?.phone,
      };
      if (Number(formState.age) < 18 || Number(formState.age) > 120) {
        return toast.error('Age should be between 18-120')
      }
      setBtnLoading(true);
      const result = await updateProfileApi(sendData);
      if (result?.success) {
        setBtnLoading(false)
        fetchProfileData()
        toast.success(result?.message)
      }
      else {
        setBtnLoading(false)
        toast.error(result?.message)
      }
    } catch (error: any) {
      setBtnLoading(false)
      toast.error(error?.data?.errors || error?.message);
    }
  }

  // if (loading) {
  //   return <Loader />
  // }

  return (
    <CustomContainer>
      <div className={'AllPageMain'}>
        <form noValidate={false} onSubmit={handleSubmit}>
          <div className={'HeadAndButton'}>
            <h2>Patient Profile</h2>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <section className={styles.ProfileImageSec}>
              <div className={styles.profileImg}>
                <img src={formState?.profile_image ? formState?.profile_image : avtarIcon} alt='Patient Image' />
              </div>
              <div className={styles.chooseProfileAndRemove}>
                <InputFile
                  title="Choose profile"
                  colorBorder={true}
                  id={"profile_image"}
                  name={"profile_image"}
                  onlyImg={true}
                  handleFileChange={handleFileChange}
                />
                <h4 onClick={RemoveProfileImg}>Remove</h4>
              </div>
            </section>
            <section className={`col-span-3 ${styles.ProfileDetailsSec}`}>
              <div className="grid grid-cols-3 gap-y-6 gap-x-16">
                <FormGroup className="customFormGroup">
                  <FormLabel className="customFormLabel">Name <span className='errorMsg'>*</span></FormLabel>
                  <TextField
                    type='text'
                    className={`custumTextField profileInput`}
                    name="name"
                    required
                    placeholder='Enter your name'
                    value={formState.name ?? ''}
                    onChange={handleInputChange}
                    onKeyPress={(event) => {
                      if (!/[a-zA-Z\s]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className="customFormGroup">
                  <FormLabel className="customFormLabel">Akos Care ID <span className='errorMsg'>*</span></FormLabel>
                  <TextField
                    type='text'
                    className={`custumTextField profileInput`}
                    name="careId"
                    disabled
                    placeholder='Enter your akos care ID'
                    value={formState.careId}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup className="customFormGroup">
                  <FormLabel className="customFormLabel">Contact Number <span className='errorMsg'>*</span></FormLabel>
                  <TextField
                    type="text"
                    className={`custumTextField profileInput`}
                    name="mobile"
                    required
                    placeholder='Enter your contact number'
                    value={formState.mobile}
                    inputProps={{
                      inputMode: 'numeric',
                      maxLength: 10,
                    }}
                    onKeyPress={handleKeyPress}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup className="customFormGroup">
                  <FormLabel className="customFormLabel">Job role</FormLabel>
                  <TextField
                    type="text"
                    className={`custumTextField profileInput`}
                    name="jobRole"
                    disabled
                    placeholder='Enter your job role'
                    value={formState.jobRole}
                  />
                </FormGroup>
                <FormGroup className="customFormGroup">
                  <FormLabel className="customFormLabel">Gender <span className='errorMsg'>*</span></FormLabel>
                  <Select
                    className={`custumTextField profileInput`}
                    placeholder="Select gender"
                    value={formState.gender}
                    name='gender'
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="others">Others</MenuItem>
                  </Select>
                </FormGroup>
                <FormGroup className="customFormGroup">
                  <FormLabel className="customFormLabel">Age</FormLabel>
                  <TextField
                    type="text"
                    className={`custumTextField profileInput`}
                    name="age"
                    placeholder='Enter your age'
                    value={formState.age}
                    inputProps={{
                      inputMode: 'numeric',
                      maxLength: 3,
                    }}
                    onKeyPress={handleKeyPress}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </div>
              <div className="mt-8 flex justify-end">
                <button className='fillButton' disabled={btnLoading}> {btnLoading && <BtnLoader />} Save profile</button>
              </div>
            </section>
          </div>
        </form>
      </div>
    </CustomContainer>
  );
};

export default ProfilePage;